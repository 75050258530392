import { Heading1 } from "../Styling/Headings"
import { TypographyRelatedCardTitle } from "../Styling/Style"
import { Link } from "gatsby-plugin-react-i18next"
import { HeroSubtitle, HeroTitle } from "../Styling/Titles"
import { CloudinaryImage } from "../Common/Cloudinary"
import { Card } from "../Cards/Cards"

export type HomepageArticleSectionProps = {
  title: string
  subject: string
  path: string
  data: Array<any>
  background?: string
  headerUrl?: string
  metadataDescription?: string
  subtitle?: string
}

const HomepageArticleSection = (props: HomepageArticleSectionProps) => {
  const articles = props.data
    .filter((article) => article.title && article.slug && article.heroImage)
    .map((article) => {
      const cloudinaryImage: CloudinaryImage = {
        imagePublicId: article.heroImage[0].public_id,
        imageId: article.heroImage[0].id || "",
        imageUrl: article.heroImage[0].url || "",
        imageSecureUrl: "",
        imageHeight: "",
        imageWidth: "",
        imageFormat: "auto",
        imageAlt: "",
      }

      return {
        ...article,
        image: { ...cloudinaryImage },
        subtitle: article.subtitle || article.metadataDescription || "",
      }
    })

  // const subtitle = articles.subtitle || articles.metadataDescription || ""

  // TODO: make images the correct size, with different resolutions

  return (
    <section className="mb-16">
      <div className="lg:hidden text-center mt-12 px-4">
        <div className="-mb-10">
          <Link to={props.headerUrl}>
            <Heading1>{props.subject}</Heading1>
          </Link>
        </div>
        <HeroSubtitle>{props.metadataDescription}</HeroSubtitle>
        <div className="h-16"></div>
      </div>

      <div className="lg:flex lg:flex-row lg:w-full">
        <div className="lg:w-5/12 lg:p-12 w-screen">
          {articles.length > 0 ? (
            <>
              <Card
                title={articles[0].subtitle}
                url={"/nb/" + props.headerUrl + "/" + articles[0].slug}
                imageHight="lg:h-128 h-full"
                image={articles[0].image}
                pillText={articles[0].title}
              />
            </>
          ) : (
            <div className="w-full h-4/6"></div>
          )}
        </div>

        <div className="lg:w-7/12 lg:p-12">
          <div className="hidden lg:block">
            <HeroSubtitle>
              <Link to={props.headerUrl}>
                <span className="flex text-left mb-16">{props.subject}</span>
              </Link>
            </HeroSubtitle>
            <HeroTitle>
              <span className="flex text-left">{props.title}</span>
            </HeroTitle>
            <br />
            <br />
          </div>

          <div className="lg:flex lg:flex-row lg:h-96 w-full">
            <div className="h-full lg:w-1/2 w-screen lg:m-5">
              {articles.length > 1 ? (
                <>
                  <Card
                    title={articles[1].subtitle}
                    url={"/nb/" + props.headerUrl + "/" + articles[1].slug}
                    imageHight="lg:h-72 h-full"
                    image={articles[1].image}
                    pillText={articles[1].title}
                  />
                </>
              ) : (
                <div className="lg:h-4/6"></div>
              )}
            </div>
            <div className="h-full lg:w-1/2 w-screen lg:m-5">
              {articles.length > 2 ? (
                <>
                  <Card
                    title={articles[2].subtitle}
                    url={"/nb/" + props.headerUrl + "/" + articles[2].slug}
                    imageHight="lg:h-72 h-full"
                    image={articles[2].image}
                    pillText={articles[2].title}
                  />
                </>
              ) : (
                <div className="lg:h-4/6"></div>
              )}
            </div>
            <Link to={props.headerUrl}>
              <div className="lg:hidden flex justify-center mt-8">
                <h3 className={TypographyRelatedCardTitle + " underline"}>
                  {"Se flere  " + props.subject.toLowerCase()}
                </h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageArticleSection

import { PrimaryBadgeLinkMediumThin } from "../Graphics/Badge"
import { HeroTitle } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"
import { Image, Placeholder, Transformation } from "cloudinary-react"
import useElementOnScreen from "../CustomHooks/useElementOnScreen"
import { Link } from "gatsby-plugin-react-i18next"

type SlidingTextType = {
  heading: string
  ingress: string
  badgeText: string
  url: string
}
const SlidingText = (props: SlidingTextType) => {
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  })
  // isVisible ? <div className="bg-gray-200 h-96 w-screen"></div> : <div className="h-96 w-screen"></div>

  return (
    <div className="w-full flex flex-col">
      <div className="h-full bg-lysgronn30-300 lg:w-8/12 lg:justify-start">
        <div className="h-24"></div>
        <div className="flex flex-wrap flex-col justify-center content-center">
          {isVisible ? (
            <div className="lg:w-5/12 w-10/12 lg:transition-opacity lg:duration-1000 lg:ease-out">
              <div className=" mb-16">
                <HeroTitle>{props.heading}</HeroTitle>
              </div>
              <div className="">
                <Ingress>{props.ingress} </Ingress>
              </div>
              <div className="flex justify-center" ref={containerRef}>
                <PrimaryBadgeLinkMediumThin title={props.badgeText} url="/om-gartnerhallen" />
              </div>
            </div>
          ) : (
            <div className="lg:opacity-50 lg:w-5/12 w-10/12 lg:transition-opacity lg:duration-1000 lg:ease-out">
              <div className=" mb-16">
                <HeroTitle>{props.heading}</HeroTitle>
              </div>
              <div className="">
                <Ingress>{props.ingress} </Ingress>
              </div>
              <div className="flex justify-center" ref={containerRef}>
                <PrimaryBadgeLinkMediumThin title={props.badgeText} url="/om-gartnerhallen" />
              </div>
            </div>
          )}

          <div className="h-10"></div>
        </div>
      </div>
    </div>
  )
}

// TODO: When text and image line up, make them sticky for a few pixels to ease the transition
// TODO: Make text fade in as showen in the design

export const HeroFrontPage = (props) => {
  return (
    <header>
      <Link to="/om-gartnerhallen">
        <div className="flex flex-col w-full">
          <div className="relative lg:sticky lg:top-0 lg:flex justify-end z-20">
            <div className="lg:w-1/2 w-full">
              <Image
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                responsiveUseBreakpoints="true"
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId={props.heroImage[0].public_id}
                alt={props.heroImage[0].context?.custom?.alt}
                loading="lazy"
                className="object-cover object-center z-0"
                secure
              >
                <Placeholder type="blur" />
                <Transformation quality="auto" fetchFormat="webp" />
                <figcaption className="mt-3 flex text-sm">
                  <svg
                    className="flex-none w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="ml-2 z-10">{"Fotograf " + props.heroImage[0].context?.custom?.caption}</span>
                </figcaption>
              </Image>
            </div>
          </div>
          <div className="lg:-mt-96 -mt-16 lg:sticky top-0">
            <SlidingText heading={props.title} ingress={props.ingress.ingress} badgeText="Bli kjent med oss" url={""} />
          </div>
        </div>
      </Link>
    </header>
  )
}

export default HeroFrontPage

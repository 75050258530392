import { CloudinaryImage } from "../Common/Cloudinary"
import { PrimaryBadgeLinkMediumThin } from "../Graphics/Badge"
import CardTemplate, { CardTemplateType } from "./CardTemplate"
import { Link } from "gatsby-plugin-react-i18next"
import { TypographyRelatedCardHeading, TypographyRelatedCardTitle } from "../Styling/Style"
import HeroBannerProps from "../Hero/HeroTypes"

export type CardType = {
  image?: CloudinaryImage
  heroImage?: HeroBannerProps
  imageHight?: string
  url?: string
  title?: string
  ingress?: string
  badgeText?: string
  badgeDisable?: boolean
  className?: string
  pillText?: string
  date?: string
}

export const Card = (props: CardType) => {
  const url = props.url || "/"

  const title = props.title || ""
  const ingress = props.ingress || ""
  const badgeText = props.badgeText || "Les mer"

  const cardTemplateProps: CardTemplateType = {
    image: props.image,
    imageHight: props.imageHight,
    className: props.className,
    pillText: props.pillText,
  }

  return (
    <CardTemplate {...cardTemplateProps}>
      <div>
        <div className="mt-2 px-4 lg:px-0">{props.date}</div>
        <Link to={url}>
          <h3 className={TypographyRelatedCardTitle + " hidden lg:block my-6"}>{title}</h3>
          <h3 className={TypographyRelatedCardHeading + " lg:hidden text-center"}>{title}</h3>
        </Link>
        <div className="px-4 lg:px-0">
          <span>{ingress}</span>
        </div>
        <div className="flex justify-center mt-4 mb-12 lg:hidden">
          {!props.badgeDisable ? (
            <PrimaryBadgeLinkMediumThin title={badgeText} url={url}></PrimaryBadgeLinkMediumThin>
          ) : (
            ""
          )}
        </div>
      </div>
    </CardTemplate>
  )
}

export const ProducerCard = (props: CardType) => {
  const url = props.url || "/"

  const title = props.title || ""
  const ingress = props.ingress || ""
  const badgeText = props.badgeText || "Les mer"

  const cardTemplateProps: CardTemplateType = {
    image: props.image,
    imageHight: props.imageHight,
    className: props.className,
  }

  return (
    <CardTemplate {...cardTemplateProps}>
      <div className="my-8">
        <Link to={url}>
          <h3 className={TypographyRelatedCardHeading + " hidden lg:block my-6 text-center"}>{title}</h3>
          <h3 className={TypographyRelatedCardHeading + " lg:hidden text-center"}>{title}</h3>
        </Link>
        <div className="sm:mx-6 md:mx-6 my-8 px-1">
          <span>{ingress}</span>
        </div>
        <div className="flex justify-center mb-12 lg:hidden">
          {!props.badgeDisable ? (
            <PrimaryBadgeLinkMediumThin title={badgeText} url={url}></PrimaryBadgeLinkMediumThin>
          ) : (
            ""
          )}
        </div>
      </div>
    </CardTemplate>
  )
}

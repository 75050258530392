import React from "react"
import { Image, Transformation } from "cloudinary-react"
import { CloudinaryImage } from "../Common/Cloudinary"
// import HeroBannerProps from "../Hero/HeroTypes"

export type CardTemplateType = {
  children?: React.ReactChild
  image?: CloudinaryImage
  imageHight?: string
  className?: string
  pillText?: string
}
export const CardImage = (props: CloudinaryImage) => {
  return (
    <>
      {props.imagePublicId ? (
        <Image
          dpr="auto"
          responsive
          hight="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          publicId={props.imagePublicId}
          alt={props.imageAlt}
          loading="lazy"
          className="object-cover object-center z-0 min-h-full"
          secure
        >
          <Transformation quality="auto" fetchFormat="webp" />
        </Image>
      ) : (
        // Image is missing. Fallback to logo
        <Image
          cloudName={process.env.CLOUDINARY_CLOUD_NAME}
          publicId="Logo/GH-stempel_RGB_wudxhq"
          alt="Gartnerhallen logo"
          loading="lazy"
          className="object-cover object-center z-0 min-h-full"
          secure
          dpr="auto"
          responsive
          hight="auto"
          crop="scale"
          responsiveUseBreakpoints="true"
        >
          <Transformation fetchFormat="auto" quality="webp" />
        </Image>
      )}
    </>
  )
}

const CardTemplate = (props: CardTemplateType) => {
  const imageHight = props.imageHight || "h-full"

  return (
    <div className={props.className}>
      <div className="w-full h-full">
        <div className={imageHight + " relative overflow-hidden"}>
          <CardImage {...props.image} />
          {props.pillText ? (
            <div className="absolute bottom-6 lg:bottom-8 mx-4 truncate max-w-full px-6 opacity-90 py-1 rounded-full bg-gray-200">
              {props.pillText}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  )
}

export default CardTemplate

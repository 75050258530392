import { graphql } from "gatsby"
import HeroFrontPage from "../components/Hero/HeroFrontPage"
import SEO, { getSeoProps } from "../components/Common/SEO"
import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import HomePageArticleSection, { HomepageArticleSectionProps } from "../components/Sections/HomepageArticleSection"
import ProductGroupsSection from "../components/Sections/ProductGroupsSection"
import { SortByDate } from "../components/Common/DateConverter"

const IndexPage = ({ location, data }) => {
  // Content
  const {
    allContentfulArticle: { nodes: articles },
    allContentfulFarmer: { nodes: farmers },
    allContentfulTopics: { nodes: topics },
    contentfulGeneric: generic,
  } = data

  // Sort all related articles from new to old. Let GH override with createdAtModified
  const sortedArticles = SortByDate(articles)

  // Show the top latest articles
  const topFeaturedArticles = sortedArticles.slice(0, 3)

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(generic, location, "website")
  helmetProps.title = "Velkommen til Gartnerhallen.no"

  const relatedFarmersProps: HomepageArticleSectionProps = {
    title: "Møt våre produsenter",
    subject: "Produsenter",
    path: "nb/bonde",
    data: farmers,
    background: "bg-white",
    headerUrl: "bonde",
  }

  const relatedArticlesProps: RelatedSectionProps = {
    title: "Aktuelt",
    path: "nb/aktuelt",
    data: topFeaturedArticles,
    headerUrl: "aktuelt",
  }

  const relatedTopicsProps: HomepageArticleSectionProps = {
    title: "Hvordan skal vi sammen møte fremtiden",
    subject: "Temaer",
    path: "nb/tema",
    data: topics,
    headerUrl: "tema",
  }

  return (
    <>
      <SEO {...helmetProps} />

      <HeroFrontPage {...generic} />

      <div className="max-w-7xl mx-auto py-6 lg:px-8">
        {generic.body?.childMdx?.body ? (
          // Generic artikkel for produktgruppeside finnse i Contenful
          <MDXRenderer>{generic.body.childMdx.body}</MDXRenderer>
        ) : (
          <div></div>
        )}

        <HomePageArticleSection {...relatedTopicsProps} />
      </div>

      {/* Produktvelger skal bruke hele skjermens bredde */}
      <ProductGroupsSection className="lg:px-32" />

      <div className="max-w-7xl mx-auto py-0 lg:py-8 lg:px-8">
        <HomePageArticleSection {...relatedFarmersProps} />
      </div>

      <RelatedSection {...relatedArticlesProps} />
    </>
  )
}

export const ariticleQuery = graphql`
  {
    allContentfulArticle(filter: { articleType: { eq: false }, public: { eq: true }, node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        public
        contentful_id
        title
        subtitle
        slug
        order
        node_locale
        metadataDescription
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        category {
          id
          slug
          category
        }
        tags {
          id
        }
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
              caption
            }
          }
          url
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        author {
          name
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulGeneric(slug: { eq: "forside" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        url
        id
        public_id
        secure_url
      }
      node_locale
      title
      metadataDescription
    }
    allContentfulFarmer(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        node_locale
        metadataDescription
        author {
          name
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        contentful_id
        title
        subtitle
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
              caption
            }
          }
          url
        }
      }
    }
    allContentfulTopics(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        slug
        node_locale
        title
        createdAt(formatString: "YYYY-MM-D")
        updatedAt(formatString: "YYYY-MM-D")
        metadataDescription
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        article {
          id
          slug
          title
        }
      }
    }
    allContentfulProductGroup(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        title
        subtitle
        slug
        node_locale
        heroImage {
          id
          public_id
          url
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
  }
`
export default IndexPage

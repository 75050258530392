import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby-plugin-react-i18next"
import { ProductGroupItems } from "../Styling/Style"
import getMainPath from "../Common/MainPaths"
import getProductColor from "../Styling/getProductColor"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { ArrowCircleLeft, ArrowCircleRight } from "../Graphics/Arrow"
import { HeroSubtitle } from "../Styling/Titles"
import { getCloudinaryImage } from "../Common/Cloudinary"

import Product from "../Graphics/Product"

const ProductGroupsSection = ({ className = "" }) => {
  const mainPath = getMainPath("productgroup")

  // Hent alle produktgrupper fra Contentful
  const data = useStaticQuery(graphql`
    {
      allContentfulProductGroup(filter: { node_locale: { eq: "nb-NO" } }) {
        nodes {
          backgroundColor
          id
          title
          slug
          ingress {
            ingress
          }
          heroImage {
            id
            public_id
            url
            secure_url
            width
            height
            format
          }
        }
      }
    }
  `)

  // sort by title
  data.allContentfulProductGroup.nodes.sort((a, b) => {
    const titleA = a.title.toUpperCase() // ignore upper and lowercase
    const titleB = b.title.toUpperCase() // ignore upper and lowercase
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    // names must be equal
    return 0
  })

  // Bygg opp liste over produktgrupper for bruk av denne komponenten.
  const dataProductGroups = data.allContentfulProductGroup.nodes.map((item, counter) => ({
    index: counter,
    slug: item.slug,
    title: item.title || "Tittel mangler i contentful",
    color: getProductColor(item.backgroundColor),
    image: getCloudinaryImage(item.heroImage[0]),
    text: item.ingress.ingress || "Text mangler i Contentful",
  }))

  // La react handtere endringer av hvilket produkt og tekst som skal vises.
  const [productGroups] = useState(dataProductGroups)

  let [currentProductGroupIndex, setCurrentProductGroupIndex] = useState(2) // Initial slide index value
  let currentProductGroup = productGroups[currentProductGroupIndex] // variable index value we can reference later

  const onMouseOver = (index) => {
    currentProductGroupIndex !== productGroups.length - 1 // Check index length
      ? setCurrentProductGroupIndex(index)
      : setCurrentProductGroupIndex((currentProductGroupIndex = index))

    currentProductGroup = productGroups[currentProductGroupIndex]
  }

  // Bygg opp en liste over producter
  const UlListProductGroupItems = dataProductGroups.map((item) => (
    <li key={item.index}>
      <Link
        to={"/nb" + mainPath + "/" + item.slug}
        className={ProductGroupItems}
        role="menuitem"
        onMouseOver={() => onMouseOver(item.index)} // onMouseOut={handleMouseOut}>
      >
        {item.title}
      </Link>
    </li>
  ))

  return (
    <div>
      <div className="hidden lg:block">
        <div className={currentProductGroup.color + " " + className}>
          {/* Product group  */}
          <div className="min-w-full h-4/5">
            <div className="grid grid-cols-4">
              {/* Column one Product Groups */}
              <div className="col-span-1 flex flex-wrap content-center">
                <ul className="space-y-10 text-right">{UlListProductGroupItems}</ul>
              </div>

              {/* Coloumn two: Product group pictures */}
              <div className="col-span-2 relative grid items-center align-middle mx-4 mt-20 mb-0 lg:mb-5 2xl:mb-24 h-128 w-auto  xl:max-w-lg 2xl:max-w-xl">
                <Link to={"/nb" + mainPath + "/" + currentProductGroup.slug}>
                  <Product image={currentProductGroup.image} />
                </Link>

                {/* <div className="transform -translate-x-8 translate-y-8 z-10"> */}
              </div>
              {/* Coloumn Three: Product group text */}

              <div className="col-span-1 flex flex-wrap content-center align-middle">
                <p className="text-header22 md:text-header24 font-sans normal text-left">{currentProductGroup.text}</p>
              </div>
            </div>
          </div>

          <div className="grid justify-items-center pb-20 xl:pb-14 2xl:pb-20">
            <Link to={"/nb" + mainPath + "/" + currentProductGroup.slug}>
              <button
                type="button"
                className="bg-transparent text-sort-200 text-button18 hover:bg-tomat-500 hover:text-white hover:underline py-2 px-8 border border-tomat-500 hover:border-transparent rounded-full content-center"
                id="user-menu"
                aria-haspopup="false"
              >
                <div className="hidden lg:inline-block">Les mer om {currentProductGroup.title.toLowerCase()}</div>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <CarouselProvider
          className="relative"
          naturalSlideWidth={330}
          naturalSlideHeight={480}
          totalSlides={5}
          visibleSlides={1.2}
        >
          <Slider>
            {dataProductGroups.map((item) => {
              return (
                <div key={item.index}>
                  <Slide index={item.index}>
                    <div className={item.color + " w-full content-center text-center px-6"}>
                      <Link to={"/nb/produktgrupper/" + item.slug}>
                        <div className="flex flex-col">
                          {/* Row one Product Groups */}
                          <div className="mt-3 sm:mt-12">
                            <HeroSubtitle>{item.title}</HeroSubtitle>
                          </div>

                          {/* Row two: Product group pictures */}
                          <div className="grid items-center align-middle mt-4 ">
                            {/* <div className="flex justify-center w-full max-h-80"> */}
                            <Product image={item.image} />
                          </div>

                          {/* Row Three: Product group text */}

                          <div className="hidden lg:block">
                            <p className="text-header22 md:text-header24 font-sans normal text-left">{item.text}</p>
                          </div>

                          {/* Row four: CTA Button */}
                          <div className="grid justify-items-center py-7 sm:py-10 ">
                            <button
                              type="button"
                              className="bg-transparent text-sort-200 text-button18 py-2 px-8 border hover:bg-tomat-500 hover:text-white hover:underline  border-tomat-500 hover:border-transparent rounded-full content-center"
                              id="user-menu"
                              aria-haspopup="false"
                            >
                              <div className="inline-block">Les mer om {item.title.toLowerCase()}</div>
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Slide>
                </div>
              )
            })}
          </Slider>
          <div className="absolute grid items-center top-1/4 right-0">
            <ButtonBack className="">
              <ArrowCircleLeft className="h-20 w-20 text-tomat-500" />
            </ButtonBack>
            <ButtonNext className="">
              <ArrowCircleRight className="h-20 w-20 text-tomat-500" />
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </div>
  )
}

export default ProductGroupsSection
